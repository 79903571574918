import { Injectable } from '@angular/core';
import { mergeLanguageConfig } from '@editor/shared/states/language/language.utilities';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Commands } from '@shared/enums/commands.enum';
import { OutcomeOptions } from '@shared/enums/outcomes.enum';
import { Rights } from '@shared/enums/rights.enum';
import { MessageData } from '@shared/models/email.model';
import { LanguageData2, LocalesData, TranslationData } from '@shared/models/locale.model';
import { LicenseUsage } from '@shared/models/plan.model';
import { IdentityData } from '@shared/models/prefs.model';
import {
  CreateOptions,
  DefaultReportSettings,
  DesignData,
  OutcomeData,
  QuestionData,
  ReleaseData,
  SharingData,
  Survey,
  SurveyData,
  SurveyHistory,
  SurveyScoring,
  SurveySetting,
  TriggerData,
} from '@shared/models/survey.model';
import { shareRef } from '@shared/operators/share-ref.operator';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { LocalesManager } from '@shared/services/locales-manager.service';
import { OutcomesManager } from '@shared/services/outcomes-manager.service';
import { QuestionsManager } from '@shared/services/questions-manager.service';
import { SnackbarService } from '@shared/services/snackbar.service';
import { TriggersManager } from '@shared/services/triggers-manager.service';
import { ZefApi } from '@shared/services/zef-api.service';
import { AccountState } from '@shared/states/account.state';
import { MoveToFolder } from '@shared/states/folders.actions';
import { FoldersState } from '@shared/states/folders.state';
import { LocalesState } from '@shared/states/locales/locales.state';
import { PrefsState } from '@shared/states/prefs.state';
import { UpdateSurveyData } from '@shared/states/survey.actions';
import { createDebug } from '@shared/states/survey.functions';
import { FilterTemplates } from '@shared/states/templates.actions';
import { TemplatesState } from '@shared/states/templates.state';
import {
  setOutcomeTranslations,
  setQuestionTranslations,
  setSharingTranslations,
  setSurveyTranslations,
} from '@shared/utilities/language.utilities';
import { firebaseSafe, isEmpty, objectArrayToArray, pickBy } from '@shared/utilities/object.utilities';
import 'firebase/database';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { SurveyShareLink } from '@shared/models/survey-shares.model';

@Injectable({
  providedIn: 'root',
})
export class SurveysManager {
  public creatingSurvey = new BehaviorSubject(false);

  constructor(
    readonly db: DatabaseWrapper,
    private store: Store,
    private za: ZefApi,
    private cf: CloudFunctions,
    private om: OutcomesManager,
    private qm: QuestionsManager,
    private lm: LocalesManager,
    readonly tm: TriggersManager,
    private ns: SnackbarService,
  ) {}

  public ownedSurveys(teamKey?: string, userKey?: string): Observable<Survey[]> {
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    return !userKey
      ? of([])
      : this.db
          .list(`/users/${userKey}/surveys/${teamKey}`, (ref) => ref.orderByValue().startAt(1))
          .snapshotChanges()
          .pipe(
            catchError(() => of([])),
            map((snapshot: any[]) =>
              snapshot
                .filter((snap) => snap.payload.val() === 5)
                .map((data) => data.key)
                .map((key) => this.connectSurvey(key, teamKey)),
            ),
          );
  }

  public surveyRespondents(surveyKey: string, teamKey?: string): Observable<number> {
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object(`/statuses/surveys/answers/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((usage: LicenseUsage) => usage?.success || 0),
        shareRef(),
      );
  }

  public surveyInvites(surveyKey: string, teamKey?: string): Observable<MessageData[]> {
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .list<MessageData>(`/emails/${teamKey}`, (ref) => ref.orderByChild('survey').equalTo(surveyKey))
      .valueChanges()
      .pipe(
        catchError(() => of([])),
        shareRef(),
      );
  }

  public teamSurveyKeys(teamKey?: string): Observable<string[]> {
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    if (!teamKey) {
      return of([]);
    }

    return this.db
      .list<SurveyData>(`/surveys/${teamKey}`)
      .snapshotChanges()
      .pipe(
        catchError(() => of([])),
        map((surveys) => (surveys || []).map((snap) => snap.payload.key)),
        shareRef(),
      );
  }

  setSurveyKeyForManagers(surveyKey: string) {
    this.qm.surveyKey.next(surveyKey);
    this.om.surveyKey.next(surveyKey);
    this.tm.surveyKey.next(surveyKey);
    this.lm.surveyKey.next(surveyKey);
  }

  updateSurvey(surveyKey: string, newData: Object) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const survey = this.db.object(`/surveys/${teamKey}/${surveyKey}`);

    return survey.update(newData);
  }

  updateDefaultReportSettings(surveyKey: string, newData: Partial<DefaultReportSettings>) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    return this.db.object(`/surveys/${teamKey}/${surveyKey}/defaultReportSettings`).update(newData);
  }

  updateSettings(surveyKey: string, settings: SurveySetting[]) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const survey = this.db.object(`/surveys/${teamKey}/${surveyKey}/settings`);

    return survey.set(settings);
  }

  updateShareTemplate(surveyKey: string, share: SurveyShareLink, remove?: boolean) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const shareObj = this.db.object(`/surveys/${teamKey}/${surveyKey}/shareTemplates/${share.type}`);

    return remove ? shareObj.remove() : shareObj.set(share);
  }

  updateDesign(surveyKey: string, newData: Object) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const design = this.db.object(`/designs/${teamKey}/${surveyKey}`);

    if (!newData['extract']) {
      newData['modified'] = true;
    }

    return design.update(newData);
  }

  updateSharing(surveyKey: string, newData: Object) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.object(`/sharing/${teamKey}/${surveyKey}`).update(firebaseSafe(newData));
  }

  updateRelease(surveyKey: string, newData: Partial<ReleaseData>): Promise<void> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.object(`/releases/${teamKey}/${surveyKey}`).update(firebaseSafe(newData));
  }

  createSurveyWithQuestions(name: string, questions: QuestionData[], design?: DesignData): Observable<string> {
    const config: Partial<CreateOptions> = {
      command: 'create_empty',
      userKey: this.store.selectSnapshot(AccountState.userKey),
      teamKey: this.store.selectSnapshot(AccountState.teamKey),
      logo: this.store.selectSnapshot(AccountState.team)?.logo,
      name,
      questions,
      ...(design ? { design } : {}),
    };

    return this.cf.postOnce(Commands.CreateSurvey, undefined, config).pipe(map((response) => response.surveyKey));
  }

  createSurvey(config: Partial<CreateOptions>) {
    config.userKey = config.userKey || this.store.selectSnapshot(AccountState.userKey);
    config.teamKey = config.teamKey || this.store.selectSnapshot(AccountState.teamKey);

    if (!['create_empty', 'create_duplicate'].includes(config.command)) {
      const activeTemplate = this.store.selectSnapshot(TemplatesState.activeTemplate);

      if (activeTemplate?.template?.language) {
        config.language = activeTemplate?.template?.language;
      }
    }

    const { logo } = this.store.selectSnapshot(AccountState.team);
    if (logo) {
      config.logo = logo;
    }

    return this.cf.postOnce(Commands.CreateSurvey, undefined, config).pipe(
      tap((response) => {
        const { surveyKey } = response;
        if (!surveyKey) {
          return createDebug(config, response);
        }

        const GoToEditor = new Navigate([`surveys/edit/${surveyKey}/build`]);
        const ResetSearch = new FilterTemplates({ query: '' });
        const selectedFolder = this.store.selectSnapshot(FoldersState.selectedFolder);

        if (selectedFolder) {
          this.store.dispatch(new MoveToFolder(selectedFolder.$key, [surveyKey]));
        }

        const { admin, command } = config;
        const createdNotification: any = {
          color: 'success',
          actionName: $localize`Open`,
          actionCallback: () => this.store.dispatch(GoToEditor),
        };

        switch (command) {
          case 'create_empty':
            return admin
              ? this.ns.open($localize`Survey created`, createdNotification)
              : this.store.dispatch([GoToEditor, ResetSearch]);

          case 'create_duplicate':
            return this.ns.open($localize`Duplicate created`, { color: 'success' });

          case 'create_from_template':
            return admin
              ? this.ns.open($localize`Survey created`, createdNotification)
              : this.store.dispatch([GoToEditor, ResetSearch]);

          case 'create_from_blueprint':
            return admin
              ? this.ns.open($localize`Survey created`, createdNotification)
              : this.store.dispatch([GoToEditor, ResetSearch]);
        }
      }),
      catchError(() => of(null)),
    );
  }

  public deleteSurvey(surveyKey: string): Observable<unknown> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    this.store.dispatch(new MoveToFolder(null, [surveyKey]));

    return from(this.db.object(`/surveys/${teamKey}/${surveyKey}`).remove()).pipe(
      switchMap(() => this.za.delete(`delete/answers/${surveyKey}`, null)),
      catchError(() => of(null)),
    );
  }

  public getSurveyData(surveyKey: string, teamKey?: string, skipTranslations?: boolean): Observable<SurveyData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<SurveyData>(`/surveys/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => (data ? { ...data, $key: surveyKey } : {}) as SurveyData),
        switchMap((data) =>
          skipTranslations
            ? of(data)
            : this.getDefaultLocalesStrings(surveyKey, teamKey).pipe(
                map((strings) => setSurveyTranslations(data, strings)),
              ),
        ),
        catchError(() => of({} as SurveyData)),
        shareRef(),
      );
  }

  public getOwnerData(surveyKey: string, teamKey?: string): Observable<IdentityData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<IdentityData>(`/surveys/${teamKey}/${surveyKey}/users`)
      .valueChanges()
      .pipe(
        switchMap((rightsData) => {
          const ownerKey = Object.keys(rightsData || {}).find((key) => rightsData[key] === Rights.OWNER);

          if (ownerKey) {
            return this.db
              .object<IdentityData>(`/prefs/${teamKey}/${ownerKey}/identity`)
              .valueChanges()
              .pipe(map((data) => ({ ...data, uid: ownerKey })));
          } else {
            return of({} as IdentityData);
          }
        }),
        catchError(() => of({} as IdentityData)),
        shareRef(),
      );
  }

  public getRightsData(surveyKey: string, teamKey?: string): Observable<Rights> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<Rights>(`/users/${userKey}/surveys/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => data || Rights.NONE),
        catchError(() => of(Rights.NONE)),
        shareRef(),
      );
  }

  public getDesignData(surveyKey: string, teamKey?: string): Observable<DesignData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<DesignData>(`/designs/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => (data ? data : ({} as any))),
        catchError(() => of({} as DesignData)),
        shareRef(),
      );
  }

  public getScoringData(surveyKey: string, teamKey?: string): Observable<SurveyScoring> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<SurveyScoring>(`/scoring/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => data || ({} as SurveyScoring)),
        catchError(() => of({} as SurveyScoring)),
        shareRef(),
      );
  }

  public getLocalesData(surveyKey: string, teamKey?: string): Observable<LocalesData> {
    return combineLatest([
      this.lm.loadSurveyLocales(surveyKey, teamKey),
      this.store.select(LocalesState.locales),
      this.store.select(LocalesState.languages),
    ]).pipe(
      map(([surveyLocales, defaultLocales, languages]: [LocalesData, LocalesData, LanguageData2[]]) => {
        const config = surveyLocales?.config || {};

        const newSurveyLocales = {
          config: {
            ...config,
          },
          strings: {},
        } as LocalesData;

        Object.entries(config).forEach(([language, configData]) => {
          newSurveyLocales.config[language] = mergeLanguageConfig(language, languages, configData);
        });

        Object.keys(newSurveyLocales.config).forEach((language) => {
          newSurveyLocales.strings[language] = {
            ...(defaultLocales?.strings?.[language] || {}),
            ...pickBy(surveyLocales?.strings?.[language] || {}, (val) => !isEmpty(val)),
          };
        });

        return newSurveyLocales;
      }),
      shareRef(),
    );
  }

  public getDefaultLocalesStrings(surveyKey: string, teamKey?: string): Observable<TranslationData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return combineLatest([
      this.store.select(PrefsState.language),
      this.getSurveyData(surveyKey, teamKey, true).pipe(map(({ language }) => language)),
    ])
      .pipe(
        map(([uiLanguage, surveyLanguage]) => surveyLanguage || uiLanguage || 'en'),
        distinctUntilChanged(),
        shareRef(),
      )
      .pipe(
        switchMap((language) =>
          this.getLocalesData(surveyKey, teamKey).pipe(map((localesData) => localesData.strings?.[language])),
        ),
        shareRef(),
      );
  }

  public getSharingData(surveyKey: string, teamKey?: string): Observable<SharingData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<SharingData>(`/sharing/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => data || ({} as SharingData)),
        switchMap((sharing) =>
          this.getDefaultLocalesStrings(surveyKey, teamKey).pipe(
            map((strings) => setSharingTranslations(sharing, strings)),
          ),
        ),
        catchError(() => of({} as SharingData)),
        shareRef(),
      );
  }

  public getReleaseData(surveyKey: string, teamKey?: string): Observable<ReleaseData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<ReleaseData>(`/releases/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => (data ? data : ({} as any))),
        map((data) => ({
          ...data,
          shareLinks: objectArrayToArray(data.shareLinks).filter((shareLink) => !!shareLink),
        })),
        catchError(() => of({} as ReleaseData)),
        shareRef(),
      );
  }

  public getQuestions(surveyKey: string, teamKey?: string): Observable<QuestionData[]> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.qm.questionsData(teamKey, surveyKey).pipe(
      map((data) => data || []),
      switchMap((questions) =>
        this.getDefaultLocalesStrings(surveyKey, teamKey).pipe(
          map((strings) => setQuestionTranslations(questions, strings)),
        ),
      ),
      catchError(() => of([])),
      shareRef(),
    );
  }

  public getOutcomes(surveyKey: string, teamKey?: string): Observable<OutcomeData[]> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.om.outcomesData(teamKey, surveyKey).pipe(
      map((data) => data || []),
      switchMap((outcomes) =>
        this.getDefaultLocalesStrings(surveyKey, teamKey).pipe(
          map((strings) => setOutcomeTranslations(outcomes, strings)),
        ),
      ),
      catchError(() => of([])),
      shareRef(),
    );
  }

  public getTriggers(surveyKey: string, teamKey?: string): Observable<TriggerData[]> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.tm.triggerData(teamKey, surveyKey).pipe(
      map((data) => data || []),
      catchError(() => of([])),
      shareRef(),
    );
  }

  public connectSurvey(surveyKey: string, teamKey?: string): Survey {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return new Survey({
      key: surveyKey,

      owner: this.getOwnerData(surveyKey, teamKey),

      rights: this.getRightsData(surveyKey, teamKey),

      survey: this.getSurveyData(surveyKey, teamKey),

      design: this.getDesignData(surveyKey, teamKey),

      sharing: this.getSharingData(surveyKey, teamKey),

      scoring: this.getScoringData(surveyKey, teamKey),

      outcomes: this.getOutcomes(surveyKey, teamKey),

      triggers: this.getTriggers(surveyKey, teamKey),

      questions: this.getQuestions(surveyKey, teamKey),

      locales: this.getLocalesData(surveyKey, teamKey),

      release: this.getReleaseData(surveyKey, teamKey),
    });
  }

  public connectHistory(surveyKey: string, teamKey?: string): SurveyHistory {
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    const survey = this.db
      .object<SurveyData>(`/history/${teamKey}/${surveyKey}/surveys`)
      .valueChanges()
      .pipe(
        map((data) => (data ? { ...data, $key: surveyKey } : data)),
        catchError(() => of({} as SurveyData)),
        shareRef(),
      );

    return {
      $key: surveyKey,
      survey,
      owner: survey.pipe(
        switchMap((surveyData) => {
          const rightsData = (surveyData && surveyData.users) || {};
          const ownerKey = Object.keys(rightsData).find((key) => rightsData[key] === Rights.OWNER);

          if (ownerKey) {
            return this.db.object<IdentityData>(`/prefs/${teamKey}/${ownerKey}/identity`).valueChanges();
          } else {
            return of({} as IdentityData);
          }
        }),
        catchError(() => of({} as IdentityData)),
        shareRef(),
      ),
      questions: this.qm.questionHistoryData(teamKey, surveyKey).pipe(
        map((data) => data || []),
        catchError(() => of([])),
        shareRef(),
      ),
    };
  }

  updateResultsOptions(surveyKey: string, resultsOptions: OutcomeOptions): Promise<void> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.object(`/surveys/${teamKey}/${surveyKey}/resultsOptions`).update(resultsOptions);
  }

  migrateSurveyLanguage(
    surveyKey: string,
    language: LanguageData2,
    existingTranslations: TranslationData,
    surveyData: SurveyData,
    questions: QuestionData[],
    outcomes: OutcomeData[],
    sharing: SharingData,
  ): Observable<unknown> {
    return this.lm
      .createLanguageLocal(surveyKey, language, existingTranslations, surveyData, questions, outcomes, sharing)
      .pipe(switchMap(() => this.store.dispatch(new UpdateSurveyData(surveyKey, { localesMigrated: true }))));
  }
}
