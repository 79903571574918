import { isURL } from 'class-validator';
import { ContactColumn } from '@shared/models/contact.model';
import { TeamData } from '@shared/models/account.model';
import { EmailData } from '@shared/models/email.model';
import { SurveyData } from '@shared/models/survey.model';
import { IdentityData, PropertyData } from '@shared/models/prefs.model';
import { SurveyShareLink } from '@shared/models/survey-shares.model';
import {
  chipPattern,
  ContactProperty,
  Property,
  SurveyProperty,
  TeamProperty,
  UserProperty,
} from '@shared/enums/properties.enum';
import { sanitizeHtml } from '@shared/utilities/string.utilities';

export interface PropertyReplaceTypes {
  team?: TeamData;
  email?: EmailData;
  columns?: ContactColumn[];
  survey?: SurveyData;
  user?: IdentityData;
  properties?: PropertyData;
  share?: SurveyShareLink;
}

function customProperty(value: string): Property {
  return ('contact_custom_' + (value || '').replace(/\s/g, '_').toLowerCase()) as Property;
}

export function hasCustomProperty(value: string) {
  return value.includes('contact_custom_');
}

export function dynamicProperties(columns: ContactColumn[]) {
  return columns.map((cc) => customProperty(cc.name));
}

export function emailProperties(columns: ContactColumn[]) {
  return columns.filter((cc) => cc.type === 'email').map((cc) => customProperty(cc.name));
}

export function textProperties(columns: ContactColumn[]) {
  return columns.filter((cc) => cc.type === 'text').map((cc) => customProperty(cc.name));
}

export function sortProperties(props: Property[]): Property[] {
  const ms = props.filter((p) => p.startsWith('my_'));
  const cs = props.filter((p) => p.startsWith('contact_') && !p.startsWith('contact_custom_'));

  const us = props.filter((p) => p.startsWith('contact_custom_'));
  const sl = props.filter((p) => p === SurveyProperty.LINK);
  const ss = props.filter((p) => p.startsWith('survey_') && p !== SurveyProperty.LINK);
  const ts = props.filter((p) => p.startsWith('org_'));

  const byName = (a: string, b: string) => a.localeCompare(b);

  return [...sl, ...ms.sort(byName), ...cs.sort(byName), ...us.sort(byName), ...ss.sort(byName), ...ts.sort(byName)];
}
/*
export function isValidProperty(prop: string) {
  prop = prop.toLowerCase();

  const validProperties = [
    ...Object.values(UserProperty),
    ...Object.values(TeamProperty),
    ...Object.values(ContactProperty),
    ...Object.values(SurveyProperty),
  ].map((p) => p.toLowerCase());

  if (prop.startsWith('contact_custom_')) {
    return true;
  } else {
    return validProperties.includes(prop);
  }
}
 */
export function propertyPreview(prop: Property, data: PropertyReplaceTypes): string {
  const { email, survey, team, properties: props, share, user } = data;
  const isAnonymous = (email && email.anonymous) || false;

  switch (prop) {
    case UserProperty.FIRST_NAME:
      return props?.firstName || user?.firstName;
    case UserProperty.LAST_NAME:
      return props?.lastName || user?.lastName;
    case UserProperty.EMAIL:
      return props?.email || user?.email;
    case UserProperty.PHONE_NUMBER:
      return props?.phone || user?.phone;
    case UserProperty.FACEBOOK:
      return props?.facebook;
    case UserProperty.LINKEDIN:
      return props?.linkedin;
    case UserProperty.TWITTER:
      return props?.twitter;
    case UserProperty.URL:
      return props?.website;

    case TeamProperty.NAME:
      return team?.name;
    case TeamProperty.FACEBOOK:
      return team?.facebook;
    case TeamProperty.LINKEDIN:
      return team?.linkedin;
    case TeamProperty.TWITTER:
      return team?.twitter;
    case TeamProperty.URL:
      return team?.website;

    case ContactProperty.PROPERTIES:
      return isAnonymous ? '' : '\nCONTACT PROPERTIES\n\n';
    case ContactProperty.NAME:
      return 'John Doe';
    case ContactProperty.FIRST_NAME:
      return 'John';
    case ContactProperty.LAST_NAME:
      return 'Doe';
    case ContactProperty.EMAIL:
      return isAnonymous ? '' : 'CONTACT EMAIL';

    case SurveyProperty.NAME:
      return `"${(survey && survey.name) || ''}"`;
    case SurveyProperty.DESCRIPTION:
      return survey && survey.description;
    case SurveyProperty.HASHTAGS:
      return share?.tags?.map((tag) => `#${tag}`).join(', ') || '';
    case SurveyProperty.ANSWERS:
      return '\n\nSURVEY ANSWERS\n\n';
    case SurveyProperty.OUTCOMES:
      return '\n\nSURVEY OUTCOMES\n\n';
    case SurveyProperty.SHARE_NAME:
      return share?.name || 'SURVEY SHARE NAME';
    case SurveyProperty.LINK:
    case SurveyProperty.ACCESSIBLE:
      return share?.url ? `https:${share?.url}` : 'https://zef.fi/s/123456';
    case SurveyProperty.QUESTION_NPS:
      return '\n\nNPS ANSWERING LINKS\n\n';
  }

  if ((prop as string).startsWith('contact_custom_')) {
    const columnKey = prop.replace('contact_custom_', '');
    const col = (data.columns || []).find((cc) => cc.$key === columnKey || customProperty(cc.name) === prop);

    if (col) {
      return ('custom column ' + col.name).toUpperCase();
    }
    // return (prop as string).replace(/_/g, ' ').toUpperCase();
  }

  return null;
}

export function invalidPropertySpan(value: string) {
  const invalidPropertyColor = '#e20046';

  return `<span style="color:${invalidPropertyColor}">${value}</span>`;
}

export function replaceProperties(source: string, data: PropertyReplaceTypes, color?: string | boolean): string {
  if (!source || source.length === 0) {
    return '';
  }

  return source
    .replace(/{(.+?)}/gm, (match: string, prop: Property): string => {
      const value = propertyPreview(prop, data);
      const isLink = prop === SurveyProperty.LINK || prop === SurveyProperty.ACCESSIBLE;

      if (value === null) {
        return invalidPropertySpan(match);
      } else if (!isLink && isURL(value)) {
        return invalidPropertySpan(value);
      } else if (value && color) {
        return `<span style="color:${color}">${sanitizeHtml(value)}</span>`;
      } else {
        return value || '';
      }
    })
    .trim();
}

export function sanitizedSource(source: string) {
  return source
    .replace(/(\S)\.(\S)/g, '$1. $2')
    .replace(/(\S)\{/g, '$1 {')
    .replace(/\}(\S)/g, '} $1')
    .replace(/\} ([,.])/g, '}$1');
}

export function replaceCustomProperties(source: string, data: PropertyReplaceTypes): string {
  return source
    .replace(/{(.+?)}/gm, (match: string, prop: Property): string => {
      if ((prop as string).startsWith('contact_custom_')) {
        const col = (data.columns || []).find((cc) => customProperty(cc.name) === prop);

        if (col) {
          return `{contact_custom_${col.$key}}`;
        }
      }

      return `{${prop}}`;
    })
    .trim();
}

export function reverseCustomProperties(source: string, data: PropertyReplaceTypes): string {
  return source.replace(/{(.+?)}/gm, (match: string, prop: Property): string => {
    if ((prop as string).startsWith('contact_custom_')) {
      const columnKey = prop.replace('contact_custom_', '');
      const col = (data.columns || []).find((cc) => cc.$key === columnKey);

      if (col) {
        return `{${customProperty(col.name)}}`;
      }
    }

    return `{${prop}}`;
  });
}

export function toPreviewString(prop: Property) {
  return `{${prop}}`;
}

/**
 * Get a list of properties in the text
 *
 * @param text
 */
export function extractProperties(text: string): Property[] {
  const props: Property[] = [];

  if (text) {
    const chips = text
      .split(chipPattern)
      .filter((segment) => segment.match(chipPattern))
      .map((matchedProp) => matchedProp.replace(/{|}/g, '').trim() as Property);

    chips.forEach((chip) => {
      if (!props.includes(chip as any)) {
        props.push(chip as any);
      }
    });
  }

  return props;
}

export function linkPropertyHref(value: string) {
  return value.startsWith('http') ? value : `https://${value}`;
}

export function linkPropertyText(value: string) {
  return value.replace('https://', '').replace('http://', '').replace(/\/$/, '');
}
