import { KeyOf } from '@shared/utilities/typescript.utilities';
import { assertArray } from '@shared/utilities/array.utilities';

export function isEmpty(value) {
  return value === undefined || value === null || value === '' || JSON.stringify(value) === '{}';
}

export function isObject(value) {
  return typeof value === 'object' && value !== null && 0 < Object.keys(value).length;
}

/**
 * Returns a copy of an object without properties filtered by provided function
 */
export function pickBy<T extends object>(obj: T, filterFn: (value, key) => boolean): Partial<T> {
  return Object.keys(obj || {}).reduce((dict, key) => {
    if (filterFn(obj[key], key)) {
      dict[key] = obj[key];
    }
    return dict;
  }, {});
}

export function pickByKeyList<T extends object>(obj: T, list: (keyof T)[] = []): Partial<T> {
  return pickBy(obj, (_, key) => list.includes(key));
}

export function excludeByKeyList<T extends object>(obj: T, list: (keyof T)[] = []): Partial<T> {
  return pickBy(obj, (_, key) => !list.includes(key));
}

export function filterEmpty<T extends object>(obj: T): T {
  return pickBy(obj, (val) => !isEmpty(val)) as T;
}

/**
 * Returns a copy of an object with nested properties flattened to a string
 *   { a: { b: 0 } } -> { 'a/b': 0 }
 */
export function flattenProps<T extends object>(obj: T, separator = '/') {
  return Object.entries(obj).reduce(
    (outerData, [outerKey, outerValue]) =>
      !isObject(outerValue)
        ? { ...outerData, [outerKey]: outerValue }
        : Object.entries(flattenProps(outerValue, separator)).reduce((innerData, [innerKey, innerValue]) => {
            const joinedKey = [outerKey, innerKey].join(separator);
            innerData[joinedKey] = innerValue;

            return innerData;
          }, outerData),
    {},
  );
}

/**
 * Returns a copy of an object with value by provided path
 */
export function setByPath<T extends object | []>(obj: T, path, value, separator = '/'): T {
  const key: string | number = path.split(separator)[0];
  const rest = path.split(separator).slice(1).join(separator);

  if (obj == null && !Number.isNaN(+path) && +path.toString().length === path.toString().length) {
    obj = [] as T;
  }

  if (Array.isArray(obj)) {
    const newArr = [...obj] as any;

    if (+key === +path && value === undefined) {
      newArr.splice(+key, 1);
    } else {
      newArr[key] = +key === +path ? value : setByPath(obj[key] || {}, rest, value, separator);
    }

    return newArr;
  } else {
    if (key === path) {
      return { ...(obj as any), [key]: value };
    } else {
      const target = obj[key] || (!Number.isNaN(+rest) && +rest.toString().length === rest.toString().length ? [] : {});

      return { ...(obj as any), [key]: setByPath(target, rest, value, separator) };
    }
  }
}

/**
 * Returns a copy of an object with nested properties expanded from a string
 *   { 'a/b': 0 } -> { a: { b: 0 } }
 */
export function expandProps<T extends object>(obj: T, separator = '/') {
  return Object.entries(obj).reduce(
    (outerData, [outerKey, outerValue]) =>
      !outerKey.includes(separator)
        ? { ...outerData, [outerKey]: outerValue }
        : { ...outerData, ...setByPath(outerData, outerKey, outerValue, separator) },
    {},
  );
}

/**
 * Returns an object with properties that are different between inputs
 */
export function diff<T extends object>(obj1: T, obj2: any): Partial<T> {
  const flattened1 = flattenProps(obj1);
  const flattened2 = flattenProps(obj2);

  const result = Object.entries(flattened1).reduce(
    (data, [key, value]) => (key in flattened2 && flattened2[key] === value ? data : { ...data, [key]: value }),
    {},
  );

  return expandProps(result);
}

export function hasChanges<T extends object>(obj1: T, obj2: any): boolean {
  return !!Object.keys(diff(obj1, obj2)).length;
}

// https://firebase.google.com/docs/database/web/structure-data?authuser=0#how_data_is_structured_its_a_json_tree
// Keys cannot contain ., $, #, [, ], /
// or ASCII control characters 0-31 or 127.
export const firebaseUnsafeChars = [
  '.',
  '$',
  '#',
  '[',
  ']',
  ...Array.from(Array(32))
    .map((_, i) => i)
    .concat(127)
    .map((code) => String.fromCharCode(code)),
];

/**
 * Returns an object with keys and values sanitized for firebase
 */
export function firebaseSafe(obj): any {
  if (!isObject(obj)) {
    return {};
  }

  // Key can contain / when it's a transaction ie. when using ref.update()
  const isTransaction = Object.keys(obj).some((key) => key.includes('/'));
  // "Myanmar Sign Tai Laing Tone-5" symbol
  // is probably not gonna be used as key character anywhere :)
  const separator = 'ꩽ';

  const dirty = isTransaction ? obj : flattenProps(obj, separator);
  const sanitized = Object.entries(dirty).reduce((data, [key, val]) => {
    if (key !== '.sv' && key.split('').every((char) => !firebaseUnsafeChars.includes(char))) {
      const value = isObject(val) ? firebaseSafe(val) : val;

      data[key] = isEmpty(value) ? null : value;
    }

    return data;
  }, {});

  return isTransaction ? flattenProps(sanitized, '/') : expandProps(sanitized, separator);
}

/**
 * Returns a hash for an object that can be used as temporary ID
 */
export function hashCode(obj, radix = 16): string {
  const text = JSON.stringify(obj);
  let hash = 0;
  let i;
  let chr;
  if (text.length !== 0) {
    /* eslint-disable no-bitwise */
    for (i = 0; i < text.length; i++) {
      chr = text.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
  }
  return Math.abs(hash).toString(radix);
}

/**
 *  Returns a number clamped between min & max
 *
 * @param num number to be clamped
 * @param min
 * @param max
 */
export function clampNumber(num: number, min: number, max: number): number {
  return Math.min(Math.max(num, min), max);
}

export function isPrimitive(value: any, skipObjects = false) {
  return (
    value === null ||
    value === undefined ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    (!skipObjects && typeof value === 'object' && Object.values(value).every((val) => isPrimitive(val, true)))
  );
}

export function jsonSafe<T extends object>(obj: T) {
  return pickBy(obj, isPrimitive);
}

export function objectArrayToArray<T>(object: any): T[] {
  if (!object || typeof object !== 'object') {
    return [];
  }

  if (Array.isArray(object)) {
    return object;
  }

  return Object.entries(object).reduce((cumm, [key, value]) => {
    cumm[key] = value;
    return cumm;
  }, []);
}

export function isShallowEqual<T extends object>(a: T, b: T): boolean {
  if (a === b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  return aKeys.every((key) => b.hasOwnProperty(key) && a[key] === b[key]);
}

// use with caution
export function isDeepEqual<T>(subj1: T, subj2: T, ignoreEmpty?: boolean): boolean {
  const processed: any[] = [];

  function isEqual(a: any, b: any): boolean {
    if (a === b || (ignoreEmpty && (a == null || a === '') && (b == null || b === ''))) {
      return true;
    }

    if (!a || !b) {
      return false;
    }

    if (typeof a !== 'object' || typeof b !== 'object') {
      return false;
    }

    if (processed.includes(a) || processed.includes(b)) {
      // don't want to handle recursive objects
      return false;
    }

    processed.push(a, b);

    const aKeys = Object.keys(a).filter((key) => !ignoreEmpty || (a[key] != null && a[key] !== ''));
    const bKeys = Object.keys(b).filter((key) => !ignoreEmpty || (b[key] != null && b[key] !== ''));

    if (aKeys.length !== bKeys.length) {
      return false;
    }

    return aKeys.every((key) => b.hasOwnProperty(key) && isEqual(a[key], b[key]));
  }

  return isEqual(subj1, subj2);
}

export function arrayToFirebaseObject<T extends { $key?: string }>(
  array: T[],
  db: { createPushId: () => string },
): Record<string, T> {
  return (array || []).reduce(
    (a, b) => {
      const key = !b.$key ? db.createPushId() : b.$key;
      a[key] = firebaseSafe(b);

      return a;
    },
    {} as Record<string, T>,
  );
}

export function firebaseObjectToArray<T>(object: Record<string, T> | T[]): T[] {
  if (Array.isArray(object)) {
    return object;
  }

  return Object.keys(object || {}).map(($key) => ({
    $key,
    ...object[$key],
  }));
}

export function hasEqualProperties<T>(obj1: T, obj2: T, props: KeyOf<T>[]): boolean {
  return props.every((prop) => obj1?.[prop] === obj2?.[prop]);
}

export function cleanEmpty<T>(obj: T): T {
  if (isEmpty(obj)) {
    return obj;
  }

  Object.entries(obj).forEach(([key, value]) => {
    if (isEmpty(value)) {
      delete obj[key];
    }
  });

  return obj;
}

export function mergeObjectWithProperties<T extends object>(
  target: T,
  source: Partial<T>,
  properties: keyof T | (keyof T)[],
): void {
  const props = assertArray(properties);
  source ||= {} as Partial<T>;
  Object.assign(target, excludeByKeyList(source as T, props));
  props.forEach((property) => Object.assign(target[property] || {}, source[property]));
}
