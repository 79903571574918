/*
 * Bypasses angular security checker for the given html string.
 *
 * @unstable
 */

import { Config } from 'dompurify';

import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { cleanHtml } from '@shared/utilities/string.utilities';
import { DOM_PURIFY_CONFIG } from '@shared/tokens/dom-purify.token';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtml implements PipeTransform {
  constructor(
    readonly sanitizer: DomSanitizer,
    @Optional() @Inject(DOM_PURIFY_CONFIG) readonly config?: Config,
  ) {}

  public transform(html: string | null): any {
    if (!html) {
      return html;
    }

    html = cleanHtml(html, this.config);

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
